import { GetStaticProps, NextPage } from 'next'
import {
  AnchorButton,
  AndroidIcon,
  AppleIcon,
  Container,
  Heading,
  Stack,
} from '@instabox/consumer-web-ui'
import { useIntl } from 'react-intl'
import {
  PageProps,
  localeToMarketCode,
  fetchMarketById,
  fetchPage,
  fetchPageLinks,
  getTranslation,
  Titles,
  ContentItem,
  Texts_With_Images,
  localeToLanguageCode,
} from '@instabox/consumer-web-shared'
import { HTMLMotionProps } from 'framer-motion'
import { useRouter } from 'next/router'
import { useMemo } from 'react'

import PageContent from '../../components/PageContent'
import InViewAnimation from '../../components/InViewAnimation'
import TextWithImage from '../../components/TextWithImage'
import returnsFirstEN from './animations/app-site-1-returns-en.json'
import returnsFirstSE from './animations/app-site-1-returns-se.json'
import returnsSecondEN from './animations/app-site-2-returns-en.json'
import returnsSecondSE from './animations/app-site-2-returns-se.json'
import placesEN from './animations/app-site-3-places-en.json'
import placesSE from './animations/app-site-3-places-se.json'
import styles from './app.module.scss'

const SLUG = 'app'

const AppPage: NextPage<PageProps> = ({ content }) => {
  const { locale } = useRouter()
  const [firstTitle, secondTitle] = content.filter(
    ({ item }) => item.__typename === 'titles',
  ) as ContentItem<Titles>[]
  const textWithImages = content.filter(
    ({ item }) => item.__typename === 'texts_with_images',
  ) as ContentItem<Texts_With_Images>[]
  const { formatMessage } = useIntl()
  const animations = useMemo(() => {
    if (localeToLanguageCode(locale) === 'en') {
      return [returnsFirstEN, returnsSecondEN, placesEN]
    }
    return [returnsFirstSE, returnsSecondSE, placesSE]
  }, [locale])

  return (
    <div className={styles.container}>
      <PageContent content={content} fields={['heroes']} slug={SLUG}>
        <Container>
          <Stack gap="lg">
            <InViewAnimation {...heroContentAnimation(0.2)}>
              <Heading fontSize="xxl" color="white" align="center" spacing="xs">
                {getTranslation('title', firstTitle?.item?.translations, firstTitle?.item?.title)}
              </Heading>
            </InViewAnimation>
            {secondTitle && (
              <InViewAnimation {...heroContentAnimation(0.4)}>
                <Heading fontSize="xxl" color="white" align="center" spacing="xs">
                  {getTranslation(
                    'title',
                    secondTitle?.item?.translations,
                    secondTitle?.item?.title,
                  )}
                </Heading>
              </InViewAnimation>
            )}
            <InViewAnimation {...heroContentAnimation(0.6)}>
              <PageContent content={content} fields={['subtitles']} slug={SLUG} />
            </InViewAnimation>
          </Stack>
        </Container>
        <div className={styles.ctas}>
          <InViewAnimation {...heroContentAnimation(0.6)}>
            <Stack layout="horizontal" align="center" justify="center" flexWrap>
              <AnchorButton
                size="large"
                variant="ghost"
                href="https://apps.apple.com/se/app/instabox/id1562697442"
                target="_blank"
                rel="noopener noreferrer"
                icon={<AppleIcon />}
                iconPosition="right">
                {formatMessage({ id: 'app_promotion.links.ios' })}
              </AnchorButton>
              <AnchorButton
                size="large"
                variant="ghost"
                href="https://play.google.com/store/apps/details?id=io.instabox.consumer"
                target="_blank"
                rel="noopener noreferrer"
                icon={<AndroidIcon />}
                iconPosition="right">
                {formatMessage({ id: 'app_promotion.links.android' })}
              </AnchorButton>
            </Stack>
          </InViewAnimation>
        </div>
      </PageContent>
      {textWithImages.map((textWithImage, index: number) => (
        <TextWithImage
          id={textWithImage.item?.id}
          key={textWithImage.item?.id}
          header={getTranslation(
            'header',
            textWithImage.item?.translations,
            textWithImage.item?.header,
          )}
          text={getTranslation('text', textWithImage.item?.translations, textWithImage.item?.text)}
          image_placement={textWithImage.item?.image_placement}
          layout="fullBleed"
          animationData={animations[index]}
        />
      ))}
    </div>
  )
}

export default AppPage

export const getStaticProps: GetStaticProps<PageProps> = async ({ locale }) => {
  const {
    data: { marketById },
  } = await fetchMarketById(localeToMarketCode(locale))
  const page = await fetchPage(locale, SLUG)
  const pageLinks = await fetchPageLinks(locale)

  return {
    props: {
      market: marketById,
      pageLinks,
      fullBleed: true,
      pageContainerVariant: 'secondary',
      ...page,
    },
    notFound: !page,
    revalidate: 60,
  }
}

const heroContentAnimation = (delay: number): HTMLMotionProps<'div'> => {
  const y = -100 * delay
  return {
    variants: {
      visible: { opacity: 1, y: '0' },
      hidden: { opacity: 0, y: `${y}px` },
    },
    transition: {
      duration: 0.7,
      ease: 'easeOut',
      delay,
    },
  }
}
